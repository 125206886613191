import {
  Image,
  SimpleGrid,
  Container,
  Heading,
  Card,
  CardBody,
  Text,
  Flex,
  Box,
  Button
} from "@chakra-ui/react";
import ImgTeam from "../images/team.svg";
import { RiArrowRightLine } from "react-icons/ri";
import { useRef } from "react";
import { useState } from "react";
import { useInView } from "framer-motion";
import ImgDigitalMarketing from "../images/digital_marketing.svg"

const AboutPage = () => {
  const [arrowPadding, setArrowPadding] = useState(2);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const ref1 = useRef(null);
  const isInView1 = useInView(ref1, { once: true });

  return (
    <Container
      maxW="container.xl"
      pt="4rem"
      id="about"
      justifyContent="center"
      px={8}
      css={{ scrollMargin: "4rem" }}
      ref={ref}
    >
      <Heading
        as="h1"
        size="3xl"
        noOfLines={4}
        bgGradient="linear(to-r, #7928CA, #FB3640)"
        bgClip="text"
        textAlign="center"
        pb={10}
        style={{
          transform: isInView ? "none" : "translateY(100px)",
          opacity: isInView ? 1 : 0,
          transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        About
      </Heading>
      <SimpleGrid spacing={10}>
        <Card
          boxShadow="md"
          style={{
            transform: isInView ? "none" : "translateY(200px)",
            opacity: isInView ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <CardBody>
            <Flex
              justifyContent="center"
              alignItems="center"
              direction="column"
              gap={10}
            >
              {/* <Box w="50%">
                <Image alignSelf="center" src={ImgTeam} alt="ZenSpyder Team" />
              </Box> */}
              <Box p={[0, 0, 5, 5]}>
                <Text color="gray.500" lineHeight={8}>
                  &emsp;&emsp; Welcome &nbsp;
                  <mark
                    style={{
                      background: "transparent",
                      color: "#FB3640",
                      fontWeight: "600",
                    }}
                  >
                    Ask Path Services
                  </mark>
                  {"  "}&nbsp;your trusted partners in accurate and reliable diagnostic services. We are committed to providing advanced pathology solutions to ensure precise health assessments and timely diagnoses. 
                  <br />
                  <br />

                  {/* <mark
                    style={{
                      background: "transparent",
                      color: "#FB3640",
                      fontWeight: "600",
                    }}
                  >
                    Our Mission
                  </mark> */}

                  <Heading
                    as="h4"
                    bgGradient="linear(to-r, #7928CA, #FB3640)"
                    bgClip="text"
                    textAlign="center"
                    pb={1}
                    style={{
                      transform: isInView ? "none" : "translateY(100px)",
                      opacity: isInView ? 1 : 0,
                      transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                    }}
                  >
                   Our Speciality
                  </Heading>


                  <br />
                  &emsp;&emsp;Comprehensive pathology services for routine and specialized testing.
                  <br />&emsp;&emsp;State-of-the-art diagnostic equipment for accurate results.

                  Highly qualified and experienced lab professionals dedicated to delivering quality services.

                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </SimpleGrid>
      <br />

    </Container>
  );
};
export default AboutPage;
