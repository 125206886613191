import {
  Image,
  SimpleGrid,
  Container,
  Heading,
  Card,
  CardBody,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";

import ImgWebDevelopment from "../images/web.jpeg"
import ImgDigitalMarketing from "../images/seo.jpeg"
import ImgClinicalResearch from "../images/clinicial_research.jpeg"
import ImgDigitalScience from "../images/digital_science.jpeg"
import ImgSoftwareTesting from "../images/testing.jpg"
import ImgJava from "../images/java.jpeg"

import ImgPharmacovigilance from "../images/Pharmacovigilance.jpeg"
import ImgMedicalCoding from "../images/medical_code.jpeg"
import ImgCDM from "../images/clinical_data_mgmt.jpeg"
import ImgRegularAffair from "../images/affairs.jpeg"
import ImgMedicalScribe from "../images/scribe.jpeg"

import { useRef } from "react";
import { useInView } from "framer-motion";

const ServicePage = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const ref1 = useRef(null);
  const isInView1 = useInView(ref1, { once: true });
  return (
    <Container
      maxW="container.xl"
      pt="4rem"
      id="services"
      justifyContent="center"
      px={8}
      css={{ scrollMargin: "4rem" }}
      ref={ref}
    >
      <Heading
        as="h1"
        size="3xl"
        noOfLines={4}
        bgGradient="linear(to-r, #7928CA, #FB3640)"
        bgClip="text"
        textAlign="center"
        pb={10}
        style={{
          transform: isInView ? "none" : "translateY(100px)",
          opacity: isInView ? 1 : 0,
          transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        Services
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={10}
        data-cues="slideInUp"
        ref={ref1}
      >

        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(-200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Heamotology and Coagulation 
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgPharmacovigilance} alt="AskPathServices Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                Hematology and coagulation are branches of laboratory medicine that study blood, blood-forming tissues, and the process of blood clotting. 
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(-200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Blood clotting
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgMedicalCoding} alt="AskPathServices Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                Also known as coagulation, this process is important for preventing excessive bleeding when a blood vessel is injured. Platelets and proteins in plasma work together to form a clot over the injury. 
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
        
  
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(-200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Hematology tests 
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgCDM} alt="AskPathServices Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                These tests examine the quantitative and qualitative changes in white blood cells, red blood cells, and platelets. A full blood count is a common hematology test. 
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(-200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Coagulation tests 
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgRegularAffair} alt="AskPathServices Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                These tests measure the amount and function of coagulation factors in the blood. A routine coagulation screening includes prothrombin time (PT), activated partial thromboplastin time (APTT), and fibrinogen level. 
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>

        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(-200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Coagulation test procedure
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgDigitalScience} alt="AskPathServices Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                  A healthcare provider will make a few tiny cuts on your lower arm, remove a blood pressure cuff, and place blotting paper on the cuts every 30 seconds until bleeding stops. 
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? "none" : "translateX(200px)",
            opacity: isInView1 ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
          Coagulation test results
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={["column", "column", "row", "row"]}
              gap={10}
            >
              <Box flexBasis={["100%", "100%", "40%", "40%"]}>
                <Image src={ImgDigitalMarketing} alt="AskPathServices Team" />
              </Box>
              <Box flexBasis={["100%", "100%", "60%", "60%"]}>
                <Text color="gray.500">
                Higher than normal levels of clotting factors may indicate a disorder that causes blood to clot more than it should. 
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </SimpleGrid>
    </Container>
  );
};
export default ServicePage;
