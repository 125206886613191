import { useState } from "react";
import {
  useColorMode,
  Flex,
  IconButton,
  Link,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import {
  RiMenu2Fill,
  RiCloseFill,
  RiMoonFill,
  RiSunFill,
} from "react-icons/ri";
import { useRef } from "react";
import { useInView } from "framer-motion";

const Header = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";
  const [display, changeDisplay] = useState("none");
  return (
    <Flex
      boxShadow={isDark ? "none" : "md"}
      height={["5rem", "5rem", "6rem", "6rem"]}
      position="fixed"
      w="100vw"
      justifyContent="center"
      zIndex={10}
      bgColor={isDark ? "#1a1917" : "#ffffff"}
      ref={ref}
      style={{
        opacity: isInView ? 1 : 0,
        transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
      }}
    >
      <Flex
        top="0"
        right="0"
        align="center"
        justify="space-between"
        w="container.xl"
        px={5}
      >
        {/* Desktop */}

        {/* <Link href="/#" display={["none", "none", "flex", "flex"]}>
          Ask Path Services
        </Link> */}
        Ask Path Services
        <Flex
          justify="space-between"
          gap={20}
          display={["none", "none", "flex", "flex"]}
        >
          <Link href="#home">Home</Link>

          <Link href="#services">Services</Link>

          <Link href="#about">About</Link>
          <Link href="#contact">Contact</Link>
        </Flex>

        {/* Mobile */}
        <IconButton
          variant="outline"
          aria-label="Open Menu"
          icon={<RiMenu2Fill />}
          onClick={() => changeDisplay("flex")}
          display={["flex", "flex", "none", "none"]}
        />

        <Tooltip
          label={isDark ? "Dark Mode" : "Light Mode"}
          css={{ position: "relative" }}
        >
          <IconButton
            onClick={toggleColorMode}
            icon={isDark ? <RiSunFill /> : <RiMoonFill />}
            variant="outline"
          />
        </Tooltip>
      </Flex>

      {/* Mobile Content */}
      <Flex
        w="100vw"
        display={display}
        bgColor={isDark ? "#1a1917" : "#ffffff"}
        h="100%"
        pos="fixed"
        top="0"
        left="0"
        overflowY="auto"
        flexDir="column"
        zIndex={5000}
      >
        <Flex justify="flex-start">
          <IconButton
            variant="outline"
            ms="1.25rem"
            mt="1.25rem"
            aria-label="Open Menu"
            icon={<RiCloseFill size={20} />}
            onClick={() => changeDisplay("none")}
          />
        </Flex>

        <Flex flexDir="column" align="center" gap={10} zIndex={10}>
          <Link href="#home" onClick={() => changeDisplay("none")}>
            Home
          </Link>

          <Link href="#services" onClick={() => changeDisplay("none")}>
            Courses
          </Link>

          <Link href="#about" onClick={() => changeDisplay("none")}>
            About
          </Link>
          <Link href="#contact" onClick={() => changeDisplay("none")}>
            Contact
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
