import {
  SimpleGrid,
  Container,
  Heading,
  Button,
  Card,
  CardBody,
  Text,
  Flex,
  //HStack,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Textarea,
  useToast,
  VStack,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import {
  // RiFacebookCircleFill,
  // RiTwitterFill,
  // RiInstagramFill,
  // RiLinkedinFill,
  RiSendPlaneFill,
  RiArrowUpLine,
} from "react-icons/ri";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { useInView } from "framer-motion";

const ContactPage = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const [arrowPadding, setArrowPadding] = useState(4);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [msgError, setMsgError] = useState("");

  const [isSending, setIsSending] = useState(false);

  const form = useRef();
  const toast = useToast();

  const processMail = (e) => {
    e.preventDefault();

    const serviceID = process.env.REACT_APP_ACCESS_EMAIL_SERVICE_ID;
    const templateID = process.env.REACT_APP_ACCESS_EMAIL_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_ACCESS_EMAIL_PUBLIC_KEY;

    let valid = true;

    /* eslint-disable no-useless-escape */
    const nameFilter = /^[a-z ,.'-]+$/i;
    if (name === "") {
      setNameError("Name can not be blank");
      valid = false;
    } else if (!nameFilter.test(name)) {
      setNameError("Invalid characters in Name");
      valid = false;
    }

    /* eslint-disable no-useless-escape */
    const emailFilter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (email === "") {
      setEmailError("Email ID can not be blank");
      valid = false;
    } else if (!emailFilter.test(email)) {
      setEmailError("Invalid Email ID");
      valid = false;
    }

    if (msg === "") {
      setMsgError("Message can not be blank");
      valid = false;
    }

    if (valid === true) {
      setIsSending(true);
      emailjs.sendForm(serviceID, templateID, form.current, publicKey).then(
        (result) => {
          setName("");
          setEmail("");
          setMsg("");
          setIsSending(false);
          toast({
            title: "Message Sent.",
            description: "We will get back to you shortly.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        },
        (error) => {
          setIsSending(false);
          toast({
            title: "Message not Sent.",
            description: "Please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      );
    }
  };
  return (
    <Container
      maxW="container.xl"
      py="5rem"
      id="contact"
      justifyContent="center"
      px={8}
      css={{ scrollMargin: "4rem" }}
      ref={ref}
    >
      <Heading
        as="h1"
        size="3xl"
        noOfLines={4}
        bgGradient="linear(to-r, #7928CA, #FB3640)"
        bgClip="text"
        textAlign="center"
        pb={10}
        style={{
          transform: isInView ? "none" : "translateY(100px)",
          opacity: isInView ? 1 : 0,
          transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        Contact
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Card
          p={[5, 5, 10, 10]}
          border="0.5px"
          borderColor="gray.100"
          boxShadow="md"
          style={{
            transform: isInView ? "none" : "translateX(-200px)",
            opacity: isInView ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading as="h2" size="lg" noOfLines={2}>
            Reach out
          </Heading>
          <CardBody p={0} mt="3rem">
            <Flex
              justifyContent="space-between"
              alignItems="flex-s"
              direction="column"
              gap={5}
            >
              <Text fontSize="lg" fontWeight="bold">
                Email ID
              </Text>
              <Text color="gray.500">askpathhinjawadi@gmail.com</Text>

              <Text fontSize="lg" mt={5} fontWeight="bold">
                Address
              </Text>
              <Text color="gray.500">
              Ask Path Services
                <br /> Chatrapati Chowk, Kaspate Wasti Wakad / Hinjewadi / Dhayari, Pune, Maharashtra, India - 411057{" "}
              </Text>

              {/* <Text fontSize="lg" mt={5} fontWeight="bold">
                Connect at
              </Text>
              <HStack mt={5} spacing="24px">
                <IconButton
                  bg="#4267B2"
                  size="lg"
                  color="white"
                  borderRadius="xl"
                  icon={<RiFacebookCircleFill size={32} />}
                />
                <IconButton
                  size="lg"
                  bg="#E1306C"
                  color="white"
                  borderRadius="xl"
                  icon={<RiInstagramFill size={32} />}
                />
                <IconButton
                  size="lg"
                  bg="#1DA1F2"
                  color="white"
                  borderRadius="xl"
                  icon={<RiTwitterFill size={32} />}
                />

                <IconButton
                  size="lg"
                  bg="#0077b5"
                  color="white"
                  borderRadius="xl"
                  icon={<RiLinkedinFill size={32} />}
                />
              </HStack> */}
            </Flex>
          </CardBody>
        </Card>
        {/* <Card
          p={[5, 5, 10, 10]}
          border="0.5px"
          borderColor="gray.100"
          boxShadow="md"
          style={{
            transform: isInView ? "none" : "translateX(200px)",
            opacity: isInView ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading as="h2" size="lg" noOfLines={2}>
            Write a Message
          </Heading>
          <CardBody p={0} mt="2rem">
            <form
              ref={form}
              onSubmit={processMail}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <FormControl isInvalid={nameError !== "" ? true : false} h="6rem">
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter Name"
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError("");
                  }}
                  id="from_name"
                  name="from_name"
                  value={name}
                  focusBorderColor="gray.400"
                />
                <FormHelperText color="red.500" fontSize="smaller">
                  {nameError !== "" ? nameError : ""}
                </FormHelperText>
              </FormControl>
              <FormControl
                isInvalid={emailError !== "" ? true : false}
                h="6rem"
              >
                <FormLabel>Email ID</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter Email ID"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                  id="from_email"
                  name="from_email"
                  value={email}
                  focusBorderColor="gray.400"
                />
                <FormHelperText color="red.500" fontSize="smaller">
                  {emailError !== "" ? emailError : ""}
                </FormHelperText>
              </FormControl>
              <FormControl isInvalid={msgError !== "" ? true : false} h="6rem">
                <FormLabel>Message</FormLabel>
                <Textarea
                  noOfLines={50}
                  placeholder="Enter Message"
                  onChange={(e) => {
                    setMsg(e.target.value);
                    setMsgError("");
                  }}
                  id="message"
                  name="message"
                  value={msg}
                  focusBorderColor="gray.400"
                />
                <FormHelperText color="red.500" fontSize="smaller">
                  {msgError !== "" ? msgError : ""}
                </FormHelperText>
              </FormControl>
              <Button
                mt="3rem"
                size="md"
                colorScheme="primary"
                onMouseOver={() => setArrowPadding(10)}
                onMouseOut={() => setArrowPadding(4)}
                type="submit"
                isLoading={isSending}
                spinner={<Spinner size="sm" />}
                loadingText="Sending "
              >
                <RiSendPlaneFill
                  id="btnSend"
                  style={{ marginRight: arrowPadding }}
                  size={20}
                />
                {isSending ? "Sending Message..." : "Send Message"}
              </Button>
            </form>
          </CardBody>
        </Card> */}
      </SimpleGrid>

      <VStack>
        <Text textAlign="center" noOfLines={2} my="1rem" pt="3rem">
          Copyright © 2025 <br /> askpath.in
        </Text>
        <Tooltip label="Scroll To Top" css={{ position: "relative" }}>
          <IconButton
            onClick={() => window.scrollTo(0, 0)}
            icon={<RiArrowUpLine size={20} />}
            variant="outline"
            borderRadius="full"
          />
        </Tooltip>
      </VStack>
    </Container>
  );
};
export default ContactPage;
