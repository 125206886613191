import {
  Image,
  SimpleGrid,
  Container,
  Stack,
  Heading,
  Button,
  Text,
  Box,
  Center
} from "@chakra-ui/react";
import ImgHome from "../images/teaching.svg";
import ImgOnlineTraining from "../images/Home_page.jpeg";
import { RiArrowRightLine } from "react-icons/ri";
import { useState } from "react";
import { useRef } from "react";
import { useInView } from "framer-motion";

const HomePage = () => {
  const [arrowPadding, setArrowPadding] = useState(2);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <Container
      maxW="container.xl"
      py="4rem"
      id="home"
      justifyContent="center"
      px={8}
      ref={ref}
    >
      {/* <div align="center">
        <Image h="0%" w="20%" src={ImgOnlineTraining} alt="Clinofytechnology Team" align="center" />
      </div> */}
      <SimpleGrid
        flexDirection={["row-reverse"]}
        columns={{ base: 1, md: 2 }}
        spacing={10}
        minH="75vh"
        pt="6rem"
      >

        <Stack
          spacing={[6, 6, 12, 12]}
          justifyContent="center"
          h="100%"
          style={{
            transform: isInView ? "none" : "translateX(-200px)",
            opacity: isInView ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Image h="auto" src={ImgOnlineTraining} alt="Ask Path Services Team" align="center" />
        </Stack>
        <Stack
          spacing={[6, 6, 12, 12]}
          justifyContent="center"
          h="100%"
          style={{
            transform: isInView ? "none" : "translateX(-200px)",
            opacity: isInView ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Heading
            as="h1"
            size="4xl"
            noOfLines={5}
            bgGradient="linear(to-l, #7928CA, #FB3640)"
            bgClip="text"
          >
            Specialize in Diagnostic
          </Heading>

          <Text color="gray.500" noOfLines={8}>
            Ask Path Services is proud to present itself as a leading diagnostic pathology laboratory, backed by a team of highly qualified and experienced lab professionals
          </Text>
          <Button
            onMouseOver={() => setArrowPadding(8)}
            onMouseOut={() => setArrowPadding(2)}
            w="50%"
            colorScheme="primary"
            size="lg"
            onClick={() => document.getElementById("services").scrollIntoView()}
          >
            Get Started
            <RiArrowRightLine
              id="btnGetStarted"
              size={24}
              style={{ marginLeft: arrowPadding }}
            />
          </Button>
        </Stack>
        {/* <Stack
          justifyContent="center"
          h="100%"
          style={{
            transform: isInView ? "none" : "translateX(200px)",
            opacity: isInView ? 1 : 0,
            transition: "all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Image h="auto" src={ImgHome} alt="Clinofytechnology Team" align="center" />
        </Stack> */}
      </SimpleGrid>
    </Container>
  );
};
export default HomePage;
