import "./App.css";
import Header from "./components/Header";
import HomePage from "./components/HomePage";
import ServicePage from "./components/ServicePage";
import AboutPage from "./components/AboutPage";
import ContactPage from "./components/ContactPage";
import { motion, useScroll } from "framer-motion";
import { useColorMode } from "@chakra-ui/react";

function App() {
  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";

  const { scrollYProgress } = useScroll();
  return (
    <div className="App" style={{ background: isDark ? "#21201e" : "#fafaf9" }}>
      <motion.div
        className="progress-bar"
        style={{
          scaleX: scrollYProgress,
          zIndex: 1000,
          background: "#FB3640",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: "6px",
          transformOrigin: "0%",
        }}
      />
      <Header toggleColorMode={toggleColorMode} isDark={isDark} />
      <HomePage />
      <ServicePage />
      <AboutPage />
      <ContactPage />
    </div>
  );
}

export default App;
